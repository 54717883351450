<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Collateral<span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mc_name" @input="$parent.removeMultiSpace($event,'mc_name')" placeholder="e.g Account Manager"
                  @keyup="$parent.removeWildChar"
                  @keydown="$parent.removeWildChar">
                  </b-form-input>
                  <VValidate 
                    name="Nama Collateral" 
                    v-model="row.mc_name" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60, alpha_spaces:1}"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                  <b-form-group>
                  <label>Digunakan untuk<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Salah Satu" v-model="row.mc_used_for" :options="Config.mr.CollateralUsed" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                  
                  <VValidate 
                    name="Digunakan untuk" 
                    v-model="row.mc_used_for" 
                    :rules="mrValidation.mc_used_for"
                  />
              </b-col>
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <label for="">Collateral Fields</label>
            <table class="table table-hover table-bordered">
              <thead>
                <tr class="table-secondary">
                  <th>Kolom Label</th>
                  <th width="200">Tipe</th>
                  <th width="400">Options</th>
                  <th width="100">Wajib Diisi</th>
                  <th width="100">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in row.mc_field" :key="k">
                  <td>
                  <input type="text" v-model="v.label" class="form-control" placeholder="e.g. Tanah dan Bangunan">
                  <VValidate 
                    :name="'Label '+(k+1)" 
                    v-model="v.label" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60}"
                  />
                  </td>
                  <td>
                    <v-select @input="changeValType(k,v.type)" placeholder="Pilih Tipe" v-model="v.type" :options="Config.mr.ColateralFields" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate 
                      :name="'Tipe '+(k+1)" 
                      v-model="v.type" 
                      :rules="{required:1}"
                    />
                  </td>
                  <!-- DYNAMIC HERE -->
                  <td v-if="v.type=='radio'||v.type=='select'||v.type=='checkbox'">
                    <b-form-tags placeholder="Type and press enter ..." remove-on-delete tag-variant="info" tag-class="text-white" v-model="v.options" />
                    <VValidate 
                      :name="'Options '+(k+1)" 
                      v-model="v.options" 
                      :rules="{required:1}"
                    />
                  </td>
                  <td v-else-if="v.type=='file'">
                    <div class="form-check" v-for="(v2,k2) in v.options" :key="k2">
                      <b-form-checkbox
                        name="checkbox-1"
                        v-model="v2.checked">
                        {{v2.label}}
                      </b-form-checkbox> 
                    </div> 
                    <small v-if="v.options.findIndex(x=>x.checked == true) == -1" class="label_error" style="">The Options {{k+1}} field is required</small>                   
                  </td>
                  <td v-else>
                  </td>
                  <td class="text-center">
                    <b-form-checkbox
                      name="checkbox-1"
                      v-model="v.isRequired"
                    >
                    </b-form-checkbox>                    
                  </td>
                  <td class="text-center">
                    <i v-if="row.mc_field.length > 1" @click="removeRow(k)" class="icon-trash"></i>
                  </td>
                  <!-- DYNAMIC HERE END -->
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <div class="text-center">
                      <button type="button" @click="addRow()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods:{
    addRow(){
      let payload = {
        "label":"", type:"text", options:"", isRequired:false
      }
      this.row.mc_field.push(payload)
    },
    removeRow(k){
      this.row.mc_field.splice(k,1)      
    },
    changeValType(k,type){
      if(type == "file"){
        this.row.mc_field[k]['options'] = [
          {"label":"Document (.pdf)", "rule_type":"pdf", "checked": false},
          {"label":"Word Files (.doc, .docx)", "rule_type":"doc,docx", "checked": false},
          {"label":"Excel Files (.xls, .xlsx)", "rule_type":"xls,xlsx", "checked": false},
          {"label":"Images (.jpg, .jpeg, .png)", "rule_type":"jpg,jpeg,png", "checked": false}
        ]
      }else{
        this.row.mc_field[k]['options'] = null
      }
    },
    doSubmit(){
      let inval = 0
      for (let i = 0; i < this.row.mc_field.length; i++) {
        if(this.row.mc_field[i]['type'] == 'file'){
          let index = this.row.mc_field[i]['options'].findIndex(x=>x.checked == true)
          if(index == -1){
            inval = inval + 1
          } 
        }
      }

      if(!inval){
        return this.$parent.doSubmit(
            "/do/BoMColateral",
            _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
            (type, resp)=>{
              if(type=='success'){
                if(this.isAccountSetting){
                  location.reload()
                }else{
                  this.$router.push({name:this.$route.name})
                }
              }else{
                if(resp.response.status==422) {
                  if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                    var msg = ""
                    $.each(Object.keys(resp.response.data),(i,v)=>{
                      msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                      console.log(i)
                    })
                    return this.$swal("Blocked With Validation!", msg)
                  }else{
                    return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                  }
                }else if(resp.response.status==400){
                  return this.$swal({
                    title: resp.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                  })
                }
              }
            },
            'POST', 'VForm', this.$refs
        )
      }
    }
  }
}
</script>